$brand-primary: #50bcba;
$brand-dark: #165e5a;
$brand-light: #92d1d7;

$theme-colors: (
        "primary": $brand-dark,
        "secondary": $brand-primary,
        "brand-primary": $brand-primary,
        "brand-dark": $brand-dark,
        "brand-light": $brand-light
);

$link-color: $brand-primary;

$grid-breakpoints: (
        xs: 0,
        sm: 640px,
        md: 768px,
        lg: 1024px,
        xl: 1280px
);

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$dropdown-link-active-color:        $dropdown-link-hover-color;
$dropdown-link-active-bg:           $dropdown-link-hover-bg;

@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/badge";

.container {
  padding-left: 0;
  padding-right: 0;
}

.btn-link {
  &:focus {
    @apply shadow-none;
  }
}

.btn-brand-primary {
  @apply text-white;
}

.btn-outline-brand-primary, .btn-outline-secondary {
  &:hover, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
    @apply text-white;
  }
}

.btn-secondary {
  @apply text-white
}

.popper {
  &--lg {
    max-width: 440px;
  }
  &--xl {
    max-width: 500px;
  }
}

.text-inherit {
  text-align: inherit;
}

.rtl {
  .form-check {
    padding-left: inherit;
    padding-right: 1.25rem;

    &-input {
      margin-left: inherit;
      margin-right: -1.25rem;
    }
  }

}