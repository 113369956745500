@tailwind base;

a {
  @apply text-blue-600;
}

th {
  @apply text-left;
}

hr {
  @apply my-4;
}

@tailwind components;
@tailwind utilities;
@import "bootstrap.scss";
@import "components.scss";
@import "_intake-chart.scss";

body, html {
  @apply text-gray-700 bg-gray-200;
}

.prose {
  max-width: none;
}

.nav-tabs {
  @apply border-b-0;

  .nav-link {
    @apply border-0 border-b-2 border-transparent text-sm font-normal text-gray-700;
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    @apply text-secondary border-secondary;
  }
}

.help-highlight {
  background-color: #00bfc0;
  display: inline-block;
  color: #fff;
  padding: 2px 3px 0 3px;
  font-size: 80%;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  text-decoration: none;
  margin: 0 5px 0;
  text-shadow: -1px 2px 3px #a0a0a0;
}

.brand-form-controls {
  .form-control,
  .react-select__control,
  .react-select__control:hover {
    @apply border-brand-primary;
  }

  .react-select__control--is-focused {
    box-shadow: 0 0 0 1px theme('colors.brand.primary');
    }

  .react-select__option--is-focused {
    @apply bg-brand-light text-brand-dark
  }

  .react-select__dropdown-indicator,
  .react-select__dropdown-indicator:hover {
    @apply text-brand-primary;
  }

  .form-group__label {
    @apply text-brand-dark font-medium;
  }
}

.popover--lg {
  max-width: 500px;
}