@import "rc_checkbox";
.form-group {
  &__label {
    @apply block text-sm leading-5 text-brand-dark mb-1;
  }

  .form-control--dark-border {
    @apply border-brand-dark;
  }


  &.has-error {
    .form-control, .react-select__control {
      @apply border-red-600;
    }

    .form-group__label {
      @apply text-red-600;
    }
  }
}

.react-datepicker-wrapper {
  @apply block;
}

.terms-links {
  @apply text-white text-xs;

  &__link {
    @apply underline text-white;
  }
}

.converter {
  @apply rounded border border-gray-300 bg-brand-light p-4;
}

.background-wave-container {
  @apply bg-right-bottom bg-no-repeat bg-contain absolute hidden pointer-events-none;
  background-image: url("assets/img/background_wave.png");
  right: -250px;
  height: 315px;
  width: 434px;
  bottom: -150px;
}

.link {
  &--white {
    @apply text-white underline;
  }
}