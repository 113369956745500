$intake-chart-dataset-average: #005CB3;
$intake-chart-dataset-low: #29B1BF;
$intake-chart-dataset-high: #E60062;

$male-reference-color: #E1B073;
$female-reference-color: #8FBE76;

.intake-chart {
    width: 100%;
    padding: 0 20px;
    @include media-breakpoint-up(sm) {
        padding: 0 40px;
    }

    &__dataset {
        margin: 0 0 25px;
        width: 100%;
    }

    &__row {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 0 5px;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
            align-items: center;
        }
        &--intake {
            font-weight: bold;
            font-size: 1rem;
        }
        &--ref {
            font-size: 0.75rem;
        }
    }

    &__label-container {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        padding-right: 30px;
        width: 100%;
        line-height: 1em;

        @include media-breakpoint-up(sm) {
            width: 175px;
            line-height: $line-height-base;
        }
    }

    &__label {
        &--intake {
            color: #333;
            line-height: 1rem;
            padding-bottom: 3px;
        }
        &--ref {
            color: #999;

            @include media-breakpoint-up(sm) {
                padding-left: 10px;
            }
        }
    }

    &__label-mobile-na {
        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    &__label-unit {
        text-transform: lowercase;
    }

    &__bar-container {
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 3px;
        line-height: 1em;

        @include media-breakpoint-up(sm) {
            padding-left: 0;
            line-height: $line-height-base;
        }
        &--hidden {
            display: none;
            @include media-breakpoint-up(sm) {
                display: flex;
            }
        }
    }

    &__bar {
        display: flex;
        align-items: center;
        transition: width 0.45s cubic-bezier(0.55, 0.085, 0.68, 0.53);
        width: 0;
        &--intake {
            height: 3px;
            background: $primary;
        }
        &--ref {
            height: 2px;
            background: #ddd;
        }
        &--superlow {
            background: $intake-chart-dataset-average;
        }
        &--low {
            background: linear-gradient(
                to right,
                $intake-chart-dataset-average 0%,
                $intake-chart-dataset-low 75%
            );
        }
        &--average {
            background: linear-gradient(
                to right,
                $intake-chart-dataset-average 0%,
                $intake-chart-dataset-low 75%
            );
        }
        &--high {
            background: linear-gradient(
                to right,
                $intake-chart-dataset-average 0%,
                $intake-chart-dataset-high 75%
            );
        }
    }

    &__bar-value {
        width: 115px;
        margin-left: -3px;
        display: flex;
        align-items: center;
        font-size:0.9rem;
        white-space:nowrap;

        @include media-breakpoint-up(sm) {
            font-size:1rem;
        }

        &--intake {
            color: $primary;
        }
        &--superlow {
              color: $intake-chart-dataset-average;
        }
        &--low {
            color: $intake-chart-dataset-low;
        }
        &--average {
            color: $intake-chart-dataset-low;
        }
        &--high {
            color: $intake-chart-dataset-high;
        }
    }
    &__bar-bullet {
        width: 10px;
        height: 10px;
        border-radius: 20px;
        margin-right:5px;
        &--intake {
            background: $primary;
        }
        &--superlow {
            background: $intake-chart-dataset-average;
        }
        &--low {
            background: $intake-chart-dataset-low;
        }
        &--average {
            background: $intake-chart-dataset-low;
        }
        &--high {
            background: $intake-chart-dataset-high;
        }

    }

    &__bullet {
        width: 10px;
        height: 10px;
        border-radius: 20px;
        margin-right:5px;
        &--female {
            background: $female-reference-color;
        }
        &--male {
            background: $male-reference-color;
        }
    }
}

